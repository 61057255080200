.app__gallery {
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.app__gallery-images {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  max-width: 95%;
  position: relative;
}

.app__gallery-images_container {
  display: flex;
  flex-direction: row;
  width: max-content;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.app__gallery-images_container::-webkit-scrollbar {
  display: none;
}

.app__gallery-images_card {
  position: relative;
  min-width: fit-content;
  margin-right: 1rem;
  margin-left: 1rem;
}

.app__gallery-images_card .gallery__image {
  max-width: 100%;
  width: max-content;
  height: auto;
  max-height: 70vh;
}

.app__gallery-images_arrows {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  position: absolute;
  bottom: 5%;
}

.gallery__arrow-icon {
  color: var(--color-golden);
  font-size: 2.5rem;
  cursor: pointer;
  background: var(--color-black);
  border-radius: 10px;
}

.gallery__arrows-div{
  background: var(--color-golden);
  padding: 3px;
  border-radius: 10px;
}

.gallery__arrow-icon:hover {
  color: var(--color-white);
}
