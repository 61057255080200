.app__navbar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-black);
  top: 0;
  position: fixed;
  padding: 1rem 1rem;
  z-index: 999;
  box-shadow: 0 0 5px 0;
  background: inherit;
  backdrop-filter: blur(15px);
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navbar__social {
  height: 40px;
  width: auto;
  margin: 0px;
}

.app__navbar-logo img {
  width: 150px;
  margin: 0px;
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.app__navbar-links li {
  margin: 0 0.8rem;
  cursor: pointer;
}

.app__navbar-links a:hover {
  color: var(--color-grey);
}

.app__navbar-login {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.app__navbar-login a {
  margin: 0 1rem;
  text-decoration: none;
  transition: 0.5s ease;
}

.app__navbar-login a:hover {
  border-bottom: 1px solid var(--color-golden);
}

.app__navbar-login div {
  width: 1px;
  height: 30px;
  background: var(--color-grey);
}

.app__navbar-smallscreen {
  display: none;
}

.app__navbar-smallscreen_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--color-black);
  transition: 0.5s ease;

  flex-direction: column;
  z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
  font-size: 27px;
  color: var(--color-golden);
  cursor: pointer;

  position: absolute;
  top: 20px;
  right: 20px;
}

.app__navbar-smallscreen_links {
  list-style: none;
}

.app__navbar-smallscreen_links .navbar__social {
  margin-bottom: 5px;
}

.app__navbar-smallscreen_links li {
  margin: 2rem;
  cursor: pointer;
  color: var(--color-golden);
  font-size: 2rem;
  text-align: center;
  justify-content: center;
  font-family: var(--font-base);
}

.app__navbar-smallscreen_links a:hover {
  color: var(--color-white);
}

/* Close button */

.navbar__close_btn {
  background: none;
  border: 0;
  box-sizing: border-box;
  color: transparent;
  cursor: pointer;
  position: absolute;
  top: 35px;
  right: 10px;
  font-size: 10px;
  line-height: 35px;
  outline: none;
  overflow: hidden;
  position: absolute;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease-in;
  width: 35px;
}

.navbar__close_btn::before,
.navbar__close_btn::after {
  background-color: var(--color-golden);
  content: "";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  transform-origin: center left;
  transition: all 0.2s ease-in;
  width: 141.4214px;
  z-index: -1;
}

.navbar__close_btn::before {
  top: 0;
  transform: rotate(45deg);
}

.navbar__close_btn::after {
  bottom: 0;
  transform: rotate(-45deg);
}

.navbar__close_btn:hover {
  color: black;
}

.navbar__close_btn:hover::before,
.navbar__close_btn:hover::after {
  height: 50px;
  transform: rotate(0deg);
}

@media screen and (min-width: 2000px) {
  .app__navbar-logo img {
    width: 210px;
  }
}

@media screen and (max-width: 991px) {
  .app__navbar-links {
    display: none;
  }

  .app__navbar-smallscreen {
    display: flex;
  }
}

@media screen and (max-width: 650px) {
  .app__navbar {
    padding: 1rem;
  }

  .app__navbar-login {
    display: none;
  }

  .app__navbar-logo img {
    width: 110px;
  }
}
