.app__form {
  padding: auto 2rem;
  box-shadow: 0px 0px 10px rgba(100, 100, 100, 0.5);
  border-radius: 10px;
}

.app__form {
  position: relative;
  margin: 1.5rem 0;
  padding: 1.5rem 1.5rem;
  border-radius: 10px;
}

.p__form {
  font-weight: 700;
  color: var(--color-golden);
}

.form__input {
  border-color: rgba(0, 0, 0, 0.2);
  border-width: 4px;
}
.is-valid {
  border-width: 4px;
}

.is-invalid {
  border-width: 4px;
}

.form__column {
  padding: 0 0.5vw;
}

.h1__submitted {
  color: var(--color-golden);
}

.app__submitted {
  border: 4px solid var(--color-golden);
}
