.app__guide {
  position: relative;
  margin: 1.5rem 0;
  padding: 1.5rem 1.5rem;
}

.app__guide-overlay {
  opacity: 0.9;
  padding: auto 2rem;
  box-shadow: 0px 0px 10px rgba(100, 100, 100, 0.5);
  border-radius: 10px;
}

.app__guide-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: var(--color-black); */
  border-radius: 10px;
}

.app__guide-content {
  width: 100%;
  z-index: 5;
}

#guide__p {
  font-weight: 700;
  margin: 10px;
}

.app__guide-content_guidelines {
  text-align: center;
}

.app__guide-content_guidelines p {
  margin: 2rem 0;
  color: rgb(208, 203, 203);
}
