.app__header {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.app__header-h1 {
  font-family: var(--font-base);
  color: rgba(195, 151, 206, 0.9);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-size: 5.5rem;
}

.app__header-img img {
  width: 85%;
}

@media screen and (max-width: 1150px) {
  .app__header-h1 {
    font-size: 90px;
  }
}

@media screen and (max-width: 730px) {
  .app__header-h1 {
    font-size: 80px;
  }
}

@media screen and (max-width: 650px) {
  .app__header-h1 {
    font-size: 65px;
    line-height: 87px;
  }
}

@media screen and (max-width: 480px) {
  .app__header-h1 {
    font-size: 50px;
    line-height: 70px;
  }
}

@media screen and (max-width: 380px) {
  .app__header-h1 {
    font-size: 2.5rem;
    line-height: 50px;
  }
}
