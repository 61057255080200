.photo {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  background-repeat: no-repeat;
  will-change: background-position;
  background-attachment: fixed;
  padding-bottom: 5vh;
  padding-left: 3vw;
}

.photo:hover {
  transform: translate(-3%, -15%);
}

@media only screen and (max-width: 540px) {
  .photo {
    padding-left: 0;
  }
}

.logo {
  margin-top: 40px;
  margin-bottom: 40px;
  font-family: "verdana", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 6px;
  font-size: 70px;
  color: transparent;
  -webkit-text-stroke: 4px white;
  /* text-shadow: -3px -3px 0 white, 3px 3px 0 white, 3px -3px 0 white, -3px -3px 0 white; */
}
.main-wrapper {
  /* background: background-color: #000000;
  background-image: linear-gradient(147deg, #000000 0%, #04619f 74%);*/
  background: url(./Battle_Underground_box_bg.png) no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  color: white;
  text-align: center;
  margin: 0px;
  overflow: auto;
}
/* header {
  left: 0;
  right: 0;
  top: 5px;
  height: 30px;
  display: flex;
  align-items: right;
}
header * {
  display: inline;
}
header li {
  margin: 20px;
}
header li a {
  text-decoration: none;
}
.img-nav {
  align-items: flex-end;
  width: 50px;
  float: left;
  margin-left: 20px;
  margin-top: 30px;
} */
img {
  width: 100px;
  float: left;
  margin-left: 20px;
  margin-top: 30px;
}
@font-face {
  font-family: "BebasNeue";
  src: local("BebasNeue"), url(./fonts/BebasNeue-Regular.ttf) format("truetype");
}
.box-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.box1 {
  /* flex: 1; */
  background-image: url("./beatBoxing.jpg");
  width: 325px;
  margin-left: 16px;
  margin-right: 16px;
  /* background: grey; */
  box-shadow: 5px 5px 12px black;
}
.box2 {
  /* flex: 1; */
  background-image: url("./rapBattle.jpg");
  width: 325px;
  margin-left: 16px;
  margin-right: 16px;
  /* background: grey; */
  box-shadow: 5px 5px 12px black;
}
.box1:hover {
  transform: scale(1.03);
}
.box2:hover {
  transform: scale(1.03);
}

.photo {
  cursor: pointer;
  margin-top: 16px;
  margin-left: 24px;
  text-align: left;
  height: 280px;
  font-size: 42px;
  font-family: BebasNeue;
}

@media only screen and (max-width: 1100px) {
  .logo {
    padding-top: 30%;
    font-size: 50px;
    letter-spacing: 4px;
    -webkit-text-stroke: 3px white;
  }
  .box-wrapper {
    flex-wrap: wrap;
  }
  .img {
    width: 50px;
  }
}

@media only screen and (max-width: 540px) {
  .img {
    width: 24px;
  }
  .logo {
    padding-top: 40%;
    font-size: 30px;
    letter-spacing: 4px;
    -webkit-text-stroke: 1px white;
  }
  .box-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .box1 {
    margin-top: 24px;
    width: 70%;
  }
  .box2 {
    margin-top: 24px;
    width: 70%;
  }
}
