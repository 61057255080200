#about {
  background: #000000;
  background: -webkit-linear-gradient(to right, #434343, #000000);
  background: linear-gradient(to right, #434343, #000000);
}

.app__aboutus {
  position: relative;
  margin: auto;
  padding: 4rem 3rem;
  text-align: center;
}

.app__aboutus-content_about {
  padding: 1rem 2rem;
  box-shadow: 0px 0px 10px rgba(100, 100, 100, 0.5);
  border-radius: 10px;
}

#aboutus__p {
  font-weight: 700;
  line-height: 1.6;
}

.app__aboutus__inner {
  margin-top: 2rem;
}

.app__aboutus-content_about {
  text-align: center;
}

.app__aboutus-content_about p {
  margin: 2rem 0;
  color: rgb(208, 203, 203);
}

#aboutUs__image {
  margin: 0;
  height: 50vh;
}

@media (max-width: 1400px) {
  #aboutUs__image {
    display: none;
  }

  .app__aboutus {
    padding: 2rem 4rem;
  }
}

/* .app__aboutus-outer {
  min-height: 100vh;
  background-color: var(--color-black);
  padding: 4rem 2rem 3rem 2rem;
  text-align: center;
  align-items: center;
}

.app__aboutus-overlay {
  opacity: 0.9;
  background: #403a3e;
  background: -webkit-linear-gradient(to right, #be5869, #403a3e);
  background: linear-gradient(to right, #be5869, #403a3e);
}

.app__aboutus-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.app__aboutus-content {
  width: 100%;
  z-index: 5;
} */
