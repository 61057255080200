.app__sponsors {
  min-height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.4);
}

.h1_sponsors {
  margin-bottom: 20px;
}

.img_sponsors {
  height: auto;
  width: 16.5vw;
  border-radius: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
  transition: 0.3s;
  margin: 10px 0;
}

.img_sponsors:hover {
  transform: scale(1.2);
}

@media (max-width: 992px) {
  .img_sponsors {
    width: 17vw;
  }
}

@media (max-width: 768px) {
  .img_sponsors {
    width: 17vw;
  }
}

@media (max-width: 650px) {
  .img_sponsors {
    width: 20vw;
  }
}

@media (max-width: 530px) {
  .img_sponsors {
    width: 35vw;
  }
}
